<template>
  <div>
    <portal to="title-page">
      <h1>Extras</h1>
    </portal>
    <v-data-table v-if="items != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      :search="search"
      fixed-header
      class="elevation-1"
    >
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-btn small color="primary" @click="$router.push({path:'/backend/admin/extras/create'})"><v-icon>mdi-plus-thick</v-icon> Extra</v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
       <template v-slot:item.order="{ item }">
        {{item.order ? item.order : 0}} <a href="#" @click="changeOrder($event, item)"><v-icon small>{{mdiPencil}}</v-icon></a>
       </template>
       <template v-slot:item.status="{ item }">
        <v-btn @click="changeStatus(item)" rounded  small :color="item.status == 'active' ? 'green' : 'red'" dark> <v-icon small>{{mdiPencil}}</v-icon> {{ item.status }}</v-btn >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon 
          class="mr-2"
          @click="$router.push({path:`/backend/admin/extras/${item.id}/edit`})"
        >
          {{mdiPencil}}
        </v-icon>
        <v-icon 
          class="mr-2"
          color="success"
          @click="$router.push({path:`/backend/admin/extras/${item.id}/images`})"
        >
          {{mdiImageMultiple}}
        </v-icon>
        <v-icon 
          class="mr-2"
          color="red"
          @click="deleteItem(item)"
        >
          {{mdiDelete}}
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-if="selectedExtra"
      v-model="dialogStatus.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Cambiar status del extra {{selectedExtra.name}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormStatus"
            lazy-validation
          >
            <v-select
              v-model="selectedExtra.status"
              :items="['active', 'inactive']"
              :rules="[v => !!v || 'El status es requerido']"
              label="Seleccione el status"
              required
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogStatus.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormStatus"
            @click="updateStatus"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedExtra"
      v-model="dialogOrder.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Cambiar orden del extra {{selectedExtra.name}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formOrder"
            v-model="validFormOrder"
            lazy-validation
          >
            <v-text-field
              v-model="selectedExtra.order"
              :rules="[rules.required,rules.number]"
              label="Orden"
              prepend-icon="order-numeric-ascending"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogOrder.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormOrder"
            @click="updateOrder"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mdiDelete, mdiPencil, mdiImageMultiple, mdiClose } from '@mdi/js';
  import Extra from "@/models/Extra.js";
  export default {
    name:'CategoriesExtras',
    data:function () {
      return {
        extraModel:new Extra,
        mdiDelete:mdiDelete,
        mdiPencil:mdiPencil,
        mdiImageMultiple:mdiImageMultiple,
        mdiClose:mdiClose,
        ////////////////////////////
        selected:[],
        tableHeaders:[
          {
            text: 'Orden',
            align: 'start',
            sortable: true,
            value: 'order',
          },
          {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Adulto',
            align: 'start',
            sortable: true,
            value: 'foreignAdult',
          },
          /*{
            text: 'A.Mayor',
            align: 'start',
            sortable: false,
            value: 'foreignElderly',
            filterable:false
          },*/
          {
            text: 'Niño',
            align: 'start',
            sortable: false,
            value: 'foreignChild',
            filterable:false
          },
          /*{
            text: 'Infante',
            align: 'start',
            sortable: false,
            value: 'foreignInfant',
            filterable:false
          },*/
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status",
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        items:null,
        search:'',
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        dialogStatus:{
          show:false
        },
        dialogOrder:{
          show:false
        },
        selectedExtra : null,
        validFormOrder:true,
        validFormStatus:true,
        rules : {
          required : v => v && v.length > 0 || 'Campo requerido',
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Numero invalido'
          },
        },
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      deleteItem(item) {
        this.$dialog.confirm(`¿Desea eliminar el extra del <strong>${item.name}</strong>? se eliminara toda su info permanentemente`, {
          loader: true // default: false - when set to true, the proceed button shows a loader when clicked.
          // And a dialog object will be passed to the then() callback
        })
        .then((dialog) => {
          this.extraModel.delete(item.id).then((message) => {
            console.log(message);
            dialog.close();
            this.snackbar.show = true;
            this.snackbar.color = 'green';
            this.snackbar.message = `El extra se elimino correctamente`;
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
            dialog.close();
            this.snackbar.show = true;
            this.snackbar.color = 'red';
            this.snackbar.message = `Error al eliminar el extra, por favor contacte al Administrador si el problema persiste`;
          });
        })
        .catch((e) => {
          console.log(e);
          // Triggered when cancel button is clicked
          console.log('Delete aborted');
        });
      },
      changeStatus(item) {
        this.selectedExtra = {... item};
        this.dialogStatus.show = true;
      },
      changeOrder(e, item) {
        e.preventDefault()
        this.selectedExtra = {... item};
        this.dialogOrder.show = true;
      },
      updateStatus() {
        let loading = this.$loading.show();
        this.extraModel.update(this.selectedExtra.id, {status:this.selectedExtra.status}).then(() => {
          loading.hide();
          this.dialogStatus.show = false;
          this.snackbar.show = true;
          this.snackbar.color = 'green';
          this.snackbar.message = `El status se actualizo correctamente`;
        })
        .catch(error => {
          console.log(error);
          this.snackbar.show = true;
          this.snackbar.color = 'red';
          this.snackbar.message = `Error al actualizar el status, por favor contacte al Administrador si el problema persiste`;
        })
      },
      updateOrder() {
        let loading = this.$loading.show();
        this.extraModel.update(this.selectedExtra.id, {order:this.selectedExtra.order}).then(() => {
          loading.hide();
          this.dialogOrder.show = false;
          this.snackbar.show = true;
          this.snackbar.color = 'green';
          this.snackbar.message = `El orden se actualizo correctamente`;
        })
        .catch(error => {
          console.log(error);
          this.snackbar.show = true;
          this.snackbar.color = 'red';
          this.snackbar.message = `Error al actualizar el orden, por favor contacte al Administrador si el problema persiste`;
        })
      }
    },
    mounted() {
      this.extraModel.getAll().onSnapshot(snap => {
        this.items = [];
        snap.forEach(doc => {
          this.items.push(doc.data());
        })
      })
    }
  }
</script>